import React, { useState, useEffect } from "react";
import "../Press.css"; // Import the CSS file
import useScreenSize from "./useScreenSize";


import breathePhoto1 from "../images/breatheConvention1.webp";
import breathePhoto2 from "../images/breatheConvention2.webp";
import breathePhoto3 from "../images/breatheConvention3.webp";
import marleyPress from "../images/marleyPress.webp"
import boldPress from "../images/boldPress.webp"
import spaceFan from "../images/spaceFan.webp"
import woodsLee from "../images/woodsLee.webp"
import womensMonth from "../images/Ayzenberg Womens Month_1522.jpeg"


// Part2
import wall from "../images/wall.jpeg";
import waiting from "../images/waiting.png";
import shop from "../images/shop.jpg";
import ignite from "../images/ignitewall.webp";
import mexico from "../images/mexico.jpg";
import kendrick from "../images/kendrick.webp";

import coachellamagazine from "../images/006_Published_in_Coachella_Magazinehttpcoachellamagazine.comreena-tolentino.webp";
import RT_in_her_artist_Atelier_2020 from "../images/007_RT_in_her_artist_Atelier_2020.webp";
import reenaflag from "../images/008_ReenaFlag.webp";
import Mamba_Billboard from "../images/010_Mamba_Billboard_featured_on_ESPN-_SPORTS_CENTER..webp";
import LA_ART_SHOW_2019 from "../images/011_LA_ART_SHOW_2019.webp";
import Collectors_and_their_game_rooms from "../images/012_Collectors_and_their_game_rooms.webp";
import LA_ART_SHOW1_2019 from "../images/013_LA_ART_SHOW_2019.webp";
import DT_LA_Art_Show2019 from "../images/014_DT_LA_Art_Show2019.webp";
import Asia_Magazine from "../images/015_Lifestyle_Asia_Magazine,_Hong_Kong_..webp";
import sitting from "../images/sitting.webp";


// Part3
import sideways from "../images/sideways.jpg";
import jedi from "../images/jedi.png";
import Painting_Astronauts from "../images/016_Painting_Astronauts.webp";
import Art_Basel_Hong_Kong from "../images/018_Art_Basel_Week._Hong_Kong..webp";
import Unleashing_Bruce from "../images/019_Unleashing_Bruce_at_Art_Basel_Week.webp";
import Bruce_versus_Kareem from "../images/021_Bruce_versus_Kareem.webp";
import Opening_Premiere from "../images/022_Opening_Premiere_Night.LA_ART_SHOW_2019.webp";
import Astronaut_John_Glen from "../images/023_Astronaut_John_GlenThe_mediums_are_Oil,_Watercolor,_and_Acrylic.18_by_20_inches.Glows_In_The_Dark.webp";
import Astronaut_Bruce_McCandles from "../images/024_Astronaut_Bruce_McCandles_.The_mediums_are_Oil,_Watercolor,_and_Acrylic.18_by_20_inches.Glows_In_The_Dark.webp";
import Astronaut_Neil_Armstrong from "../images/025_Astronaut_Neil_ArmstrongThe_mediums_are_Oil,_Watercolor,_and_Acrylic.18_by_20_inches.Glows_In_The_Dark.webp";
import ballerstatus from "../images/026_httpswww.ballerstatus.com20181205introducing-rt-la-artist-pouring-her-heart-into-her-art.webp";
import Immersive_Presentations_at_IAC from "../images/028_Immersive_Presentations_at_IAC._Astronauts,_Artronauts,_Alchemnauts_and_Play.webp";
import kovr from "../images/kovr.webp";

// part iv
import David_Bowie_Glows_in_the_dark from "../images/030_David_Bowie._Glows_in_the_dark.webp";
import Santa_Monica_CA from "../images/031_Santa_Monica_CA.webp";
import biggie from "../images/032_sold.webp";
import bowie from "../images/033_sold.webp";
import chester from "../images/034_sold.webp";
import tupac from "../images/035_sold.webp";
import williams from "../images/036_sold.webp";
import aaliyah from "../images/037_sold.webp";
import Naked_Magazine from "../images/038_Naked_Magazine_Exclusive_Artist_Editorial.webp";
import THE_JOKER_LIVES18_by_18 from "../images/039_s_o_l_d.THE_JOKER_LIVES18_by_18.webp";
import PUSH_MAGAZINE from "../images/040_PUSH_MAGAZINE_.webp";
import Elegant_Magazine from "../images/041_Elegant_Magazine_spread_and_interview.webp";
import britcobain from "../images/britcobain.webp";
import selenatina from "../images/selenatina.webp";
import dianapac from "../images/dianapac.webp";


// part v
import Skirball_Center_ from "../images/042_Skirball_Center_.webp";
import Skirball_Center_Private from "../images/043_Skirball_Center__Private_Exhibit_on_October_29,_2017.webp";
import Reena_Princess_Diana_and_Kurt_Cobain from "../images/044_Reena_diana.webp";
import Gone_But_Never_Forgotten_August_2016 from "../images/045_First_Art_show.Hollywood_Icons_1990sGone_But_Never_Forgotten_August_2016.webp";
import Hollywood_Icons_1990sGone_ from "../images/Gonegallery.webp";
import Audrey_Hepburns_Tryptych from "../images/047_Audrey_Hepburns_Tryptych.webp";
import Art_and_Red_Carpets from "../images/048_Art_and_Red_Carpets.webp";
import cusp from "../images/cusp.webp";
import koncept from "../images/koncept.webp";

import brittany from "../images/brittanymurphy.webp";
import leia from "../images/Leia_590x.webp";
import twinflame from "../images/twinFlame.webp";
import yubari from "../images/yubari.jpg";
import meetrt from "../images/meetRTcopy.webp";
import runcover from "../images/rundmcCover.jpg";
import reenabrush from "../images/reena_Brushes.jpg";
import marilyn from "../images/marilyn.jpg";
import magazine from "../images/magazine.jpg";
import selene from "../images/space.jpg";
import peace from "../images/peace.jpg";
import universe from "../images/astronaut/spaceuniverse.webp";
import runvinyl from "../images/runvinyl.webp";
import runalbum from "../images/runalbum.webp";

const images = [
  { image: universe, name: "The Universe" },
  { image: peace, name: "In the mixture. #martinimages2021" },
  { image: runalbum, name: "RUN DMC x 12 ON 12 VINYL" },
  {
    image: "https://img.youtube.com/vi/9ll2QJACpMg/maxresdefault.jpg",
    name: "THE MAKING OF RUN DMC x 12 ON 12 Vinyl Collaboration",
    video: "https://www.youtube.com/embed/9ll2QJACpMg",
  },
  { image: runcover, name: "RUN DMC x 12 on 12 Vinyl" },
  { image: runvinyl, name: "RUN DMC x 12 ON 12 VINYL" },
  { image: reenabrush, name: "Photographed by Rob Polgar 2020" },
  {
    image: "https://img.youtube.com/vi/66eV8QlYTMQ/maxresdefault.jpg",
    name: "RT paints (Original Work of Art of Basquiat)",
    video: "https://www.youtube.com/embed/66eV8QlYTMQ",
  },
  {
    image: yubari,
    name: "https://taintedmagazine.com/new-frontiers-in-the-recent-work-of-reena-tolentino/",
  },
  {
    image: breathePhoto1,
    name: "Breathe Convention Conference, Las Vegas 2023",
  },
  {
    image: breathePhoto3,
    name: "RT as the lead curator for the Breathe Convention Conference, Las Vegas 2023",
  },
  {
    image: breathePhoto2,
    name: "Breathe Convention Conference, Las Vegas 2023",
  },
  { image: brittany, name: "Brittany Murphy" },
  { image: leia, name: "Leia" },
  { image: twinflame, name: "Twin Flame" },
  { image: marilyn, name: "Marilyn Monroe" },
  { image: magazine, name: "Elegant Magazine spread and interview" },
  { image: womensMonth, name: "Whitney Houston at the Ayzenberg Ad Agency" },
  { image: selene, name: "2021" },
  // part2
  {
    image: ignite,
    name: "Reena standing in front of her wall at Ignite Art Show    (photo credit: Sawyer Productions)",
  },
  {
    image: wall,
    name: "The wall layout at   Pre Opening Night of Ignite Art Show",
  },
  { image: boldPress, name: "Bold Magazine Editorial Publishings 2023" },
  { image: marleyPress, name: "Bold Magazine Editorial Publishings 2023" },
  {
    image: mexico,
    name: "RT x St. Regis Lifestyle Travel ",
    video: "https://www.youtube.com/embed/2CR3dehBgHA",
  },
  {
    image: kendrick,
    name: "Kendrick Lamar by Reena Tolentino",
    video: "https://www.youtube.com/embed/_3GUUqvyNO0",
  },
  { image: waiting, name: "UBS Collectors Lounge  Miami" },
  { image: shop, name: "Bruce versus Kareem." },
  { image: DT_LA_Art_Show2019, name: "DT LA Art Show 2019" },
  {
    image: coachellamagazine,
    name: "Published in Coachella Magazine http://coachellamagazine.com/reena-tolentino/",
  },
  {
    image: RT_in_her_artist_Atelier_2020,
    name: "RT in her Artist Atelier, 2020",
  },
  { image: reenaflag, name: "Published in Coachella Magazine 2021" },
  { image: Asia_Magazine, name: "Lifestyle Asia Magazine, Hong Kong" },
  {
    image: woodsLee,
    name: "Tiger Woods and Bruce Lee at the Messenger Gallery Exhibition 2023",
  },
  {
    image: Mamba_Billboard,
    name: "Mamba Billboard featured on ESPN- SPORTS CENTER.",
  },
  { image: LA_ART_SHOW_2019, name: "LA Art Show 2019" },
  {
    image: Collectors_and_their_game_rooms,
    name: "Collectors and Their Game Rooms",
  },
  { image: LA_ART_SHOW1_2019, name: "LA Art Show 2019" },
  { image: sitting, name: "" },
  // part 3
  { image: Painting_Astronauts, name: "Painting Astronauts" },
  { image: kovr, name: "Rekovr." },
  { image: Art_Basel_Hong_Kong, name: "Art Basel Week. Hong Kong" },
  { image: Unleashing_Bruce, name: "Unleashing Bruce at Art Basel Week" },
  { image: Bruce_versus_Kareem, name: "Bruce Versus Kareem" },
  {
    image: Opening_Premiere,
    name: "Opening Premiere Night, LA Art Show 2019",
  },
  {
    image: Astronaut_John_Glen,
    name: "Astronaut John Glen, Glows in the Dark",
  },
  {
    image: Astronaut_Bruce_McCandles,
    name: "Astronaut Bruce McCandles .  The mediums are Oil, Watercolor, and Acrylic.  18 by 20 inches.  Glows In The Dark",
  },
  {
    image: Astronaut_Neil_Armstrong,
    name: "Astronaut Neil Armstrong   The mediums are Oil, Watercolor, and Acrylic.  18 by 20 inches.  Glows In The Dark",
  },
  {
    image: spaceFan,
    name: "Poet Keirock in front of 'Floating In Space' painting by RT",
  },
  { image: jedi, name: "http://voyagela.com/interview/check-r-t-s-artwork/" },
  {
    image: ballerstatus,
    name: "https://www.ballerstatus.com/2018/12/05/introducing-rt-la-artist-pouring-her-heart-into-her-art/",
  },
  { image: sideways, name: "2019 studio photoshoot with  Desmond." },
  {
    image: Immersive_Presentations_at_IAC,
    name: "Immersive Presentations at IAC",
  },
  // part iv
  { image: Santa_Monica_CA, name: "Santa Monica, CA" },
  {
    image: David_Bowie_Glows_in_the_dark,
    name: "David Bowie, Glows in the Dark",
  },
  { image: biggie, name: "(Sold)" },
  { image: bowie, name: "(Sold)" },
  { image: chester, name: "(Sold)" },
  { image: tupac, name: "(Sold)" },
  { image: williams, name: "(Sold)" },
  { image: aaliyah, name: "(Sold)" },
  {
    image: Naked_Magazine,
    name: "Naked Magazine Exclusive Artist Editorial",
  },
  {
    image: THE_JOKER_LIVES18_by_18,
    name: "'The Joker Lives' 18 by 18 (Sold)",
  },
  {
    image: PUSH_MAGAZINE,
    name: 'PUSH MAGAZINE issue 3 "INTERVIEWS WITH WOMEN BEHIND LA`S COOLEST STREET ART"  Photo by Khara',
  },
  { image: Elegant_Magazine, name: "Elegant Magazine Spread and Interview" },
  { image: britcobain, name: "" },
  { image: dianapac, name: "" },
  { image: selenatina, name: "" },
  // part v
  { image: koncept, name: "Koncept Magazine spread and interview" },
  {
    image: Reena_Princess_Diana_and_Kurt_Cobain,
    name: 'Reena in front of her original art pieces of Princess Diana and Kurt Cobain for the Exhibition "Endless Summer" series, July 2017.',
  },
  {
    image: Hollywood_Icons_1990sGone_,
    name: 'RT at her solo exhibition " Hollywood Icons 1990s:  Gone But Never Forgotten" August 2016',
  },
  {
    image: Gone_But_Never_Forgotten_August_2016,
    name: 'First Art show.  "Hollywood Icons 1990s   Gone But Never Forgotten" August 2016',
  },
  {
    image: Skirball_Center_Private,
    name: "Skirball Center Private Exhibit, October 29, 2017",
  },
  {
    image: Skirball_Center_,
    name: "Skirball Center Private Exhibit, October 29, 2017",
  },
  { image: Audrey_Hepburns_Tryptych, name: "Audrey Hepburn's Tryptych" },
  { image: Art_and_Red_Carpets, name: "Art and Red Carpets" },
  { image: cusp, name: "RAW LA  2017 at Exchange LA." },
];

const images1 = [{ image: meetrt, title: "Meet" }];



const Collection = () => {
  const [overlayImage, setOverlayImage] = useState(null);
  const [isVideo, setIsVideo] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const [currentImageName, setCurrentImageName] = useState("");
  const [currentIndex, setCurrentIndex] = useState(null);
  const isMobile = useScreenSize("(max-width: 768px)");

  // Function to check if a name is a URL
  const isUrl = (name) => {
    return name.startsWith("http://") || name.startsWith("https://");
  };


  useEffect(() => {
    const handleKeyDown = (event) => {
      if (overlayImage) {
        if (event.key === "ArrowLeft") {
          prevImage();
        } else if (event.key === "ArrowRight") {
          nextImage();
        } else if (event.key === "Escape") {
          closeOverlay();
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [overlayImage]);

  const closeOverlay = () => {
    setOverlayImage(null);
    setIsVideo(false);
    setVideoUrl(null);
  };

  const prevImage = () => {
    const newIndex = currentIndex === 0 ? images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
    const currentImage = images[newIndex];
    setOverlayImage(currentImage.image);
    setCurrentImageName(currentImage.name);
    if (currentImage.video) {
      setVideoUrl(currentImage.video);
      setIsVideo(true);
    } else {
      setVideoUrl("");
      setIsVideo(false);
    }
  };

  const nextImage = () => {
    const newIndex = currentIndex === images.length - 1 ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
    const currentImage = images[newIndex];
    setOverlayImage(currentImage.image);
    setCurrentImageName(currentImage.name);
    if (currentImage.video) {
      setVideoUrl(currentImage.video);
      setIsVideo(true);
    } else {
      setVideoUrl("");
      setIsVideo(false);
    }
  };

  // In the handleClick function, make sure to update the currentIndex to match the clicked image
  const handleClick = (image, name, video, index) => {
    setOverlayImage(image);
    setCurrentImageName(name);
    setCurrentIndex(index);
    if (video) {
      setVideoUrl(video);
      setIsVideo(true);
    } else {
      setVideoUrl("");
      setIsVideo(false);
    }
  };

  return (
    <>
      <div className="gallery-content">
        {isMobile ? (
          <div className="gallery-container-mobile">
            <img loading="lazy"
              className="image1-mobile"
              src={meetrt}
              alt={images1.name}
              draggable="false"
            />
            {images.map((item, index) => (
              <div
                className="gallery-item2"
                key={index}
                onClick={() =>
                  handleClick(item.image, item.name, item.video, index)
                } // Pass index here
              >
                <img loading="lazy"
                  src={item.image}
                  alt={`image-${index}`}
                  className="gallery-image"
                />
                <a
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="gallery-link-mobile"
                >
                  {item.name}
                </a>
              </div>
            ))}
          </div>
        ) : (
          <div className="gallery-container">
            <img loading="lazy"
              className="image1"
              src={meetrt}
              alt={images1.name}
              draggable="false"
            />
            {images.map((item, index) => (
              <div
                className="gallery-item2"
                key={index}
                onClick={() =>
                  handleClick(item.image, item.name, item.video, index)
                } // Pass index here
              >
                <img loading="lazy"
                  src={item.image}
                  alt={`image-${index}`}
                  className="gallery-image"
                />
                <a
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="gallery-link"
                >
                  {item.name}
                </a>
              </div>
            ))}
          </div>
        )}

        {/* Overlay for displaying clicked image or video */}
        {overlayImage && (
          <div className="image-overlay visible" onClick={closeOverlay}>
            <div
              className={
                isMobile
                  ? "image-overlay-content-mobile"
                  : "image-overlay-content"
              }
            >
              {isVideo && videoUrl ? (
                <iframe
                  width="100%"
                  height="100%"
                  src={videoUrl}
                  title="Video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              ) : (
                <img loading="lazy" src={overlayImage} alt="Full view" />
              )}

              <div className={isMobile ? "image-name-mobile" : "image-name"}>
                {isUrl(currentImageName) ? (
                  <a
                    href={currentImageName}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {currentImageName}
                  </a>
                ) : (
                  currentImageName
                )}
              </div>

              {/* Arrows to navigate between images */}
              <div className="navigation-arrows">
                <button
                  className="prev-arrow"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent overlay from closing
                    prevImage();
                  }}
                >
                  {/* Left arrow symbol */}
                </button>

                <button
                  className="next-arrow"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent overlay from closing
                    nextImage();
                  }}
                >
                  {/* Right arrow symbol */}
                </button>
              </div>
            </div>
            <div className="close-button" onClick={closeOverlay}>
              &times;
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Collection;
