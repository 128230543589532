import "../../App.css";
import Collection from "../CollectionComponent";


import image001 from "../../images/picasso/001_001_image.webp";
import image003 from "../../images/picasso/003_003_Youre_A_Vibe_36x42_Acrylic_and_oil_pastel_on_canvas.webp";
import image023 from "../../images/picasso/023.webp"
import image004 from "../../images/picasso/004_004_Father_protecting_Family_17x21_Acrylic_on_canvas.webp";
import image005 from "../../images/picasso/005_005_Intertwine_Together_12x16_Acrylic_on_canvas.webp";
import image006 from "../../images/picasso/006_006_Father_protecting_Family_17x21_Acrylic_on_canvas.webp";
import image007 from "../../images/picasso/007_007_Mother_cradling_Baby_13x15_Acrylic_on_canvas.webp";
import image008 from "../../images/picasso/008_008_Mother_playing_with_Infant_and_Child_15x20_Acrylic_on_canvas.webp";
import image009 from "../../images/picasso/009_009_Mother_with_Infant_and_Child_12x16_Acrylic_on_canvas.webp";


const images = [
  { image: image001, name: "" }, // No caption provided
  { image: image023, name: "Motherhood." },
  {
    image: image003,
    name: "You're A Vibe 36x42 Acrylic and oil pastel on canvas",
  },
  {
    image: image008,
    name: "Mother playing with Infant and Child 15x20 Acrylic on canvas",
  },
  {
    image: image004,
    name: "Father protecting Family 17x21 Acrylic on canvas",
  },
  { image: image007, name: "Mother cradling Baby 13x15 Acrylic on canvas" },
  { image: image005, name: "Intertwine Together 12x16 Acrylic on canvas" },
  {
    image: image006,
    name: "Father protecting Family 17x21 Acrylic on canvas",
  },
  {
    image: image009,
    name: "Mother with Infant and Child 12x16 Acrylic on canvas",
  },
];




const Collections = () => {
  return <Collection images={images} />;
};

export default Collections;