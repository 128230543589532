import React, { useState, useEffect } from "react";
import "../Press.css"; // Import the CSS file

import BioPic from '../images/biography/bio_infusion_lounge.jpg';
import Fely from '../images/biography/trivia_fely_franquelli.jpg';


const Gallery = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  return (
    <>
      {isMobile ? (
        <div className="gallery-container-mobile">
          <div className="bio-image-mobile">
          <img src={BioPic} alt="Biography" />
          </div>
          <section className="biography-mobile">
            <div className="bio-content-mobile">
              <h2>BIOGRAPHY</h2>
              <p>
                {/* Your biography content goes here */}
                In the heart of Hollywood, amidst the glitz and glamour, Reena's
                formative years were shaped by her single mother's unwavering
                resilience, a testament to the immigrant spirit from the
                Philippines. From early on, she embodied the virtues of
                perseverance and forward-thinking, laying the groundwork for her
                journey ahead.
              </p>
              <p>
                Under the guidance of legendary mentors such as Lee Strasberg,
                Stella Adler, and Sanford Meisner at Playhouse West, Reena
                embarked on her artistic odyssey. Her debut alongside the iconic
                Aaliyah in "Journey To The Past" marked a profound chapter in
                her soul-stirring narrative.
              </p>
              <p>
                With a repertoire spanning blockbuster films and beloved TV
                series like "Married With Children" and "iCarly," Reena's talent
                graced screens worldwide. Not just confined to the silver
                screen, she lent her charisma to national advertising campaigns
                for renowned brands like Adidas and Coca-Cola, leaving an
                indelible imprint.
              </p>
              <p>
                The inception of her Reena Clone Blog series ignited a newfound
                passion for storytelling, propelling her into the realms of
                producing, editing, and scripting. Armed with a Communication
                Studies degree and a trail of accolades including the
                prestigious Astronaut of the Year Award, her multifaceted
                talents earned her global acclaim.
              </p>
              <p>
                Balancing between ateliers in Los Angeles and England, Reena's
                art transcends boundaries, resonating with audiences across
                diverse cultures. Her installations, from Hong Kong to Berlin,
                including poignant tributes to Kobe Bryant, adorn iconic
                streets, echoing her commitment to democratizing art.
              </p>
              <p>
                In a landmark moment, Reena made history as the first Pacific
                Islander contemporary painter to address Cambridge University's
                Conference of Human Analog Space Missions. Her impassioned
                discourse catalyzed the creation of "The Ordeal," a monumental
                painting embodying her Quantum Entanglement narrative.
              </p>
              <p>
                {" "}
                Today, as an Artist in Residency at Pas + Fig in Highland Park,
                California, Reena pioneers immersive experiences, pushing the
                boundaries of artistic expression. Inspired by her deep-seated
                commitment to social responsibility, she founded the ARTE Youth
                Program, bridging gaps in S.T.E.A.M education and spreading
                transformative experiences to impoverished communities
                worldwide.
              </p>
              <p>Ad Astra!</p>
            </div>
          </section>

            <div className="bio-image-mobile">
              <img src={Fely} alt="Trivia" />
            </div>
          <section className="biography-mobile">
            <div className="bio-content-mobile">
              <h2>Reena Trivia!</h2>
              <p>
                Reena's Great Grand Aunt, Fely Franquelli, was " HOLLYWOOD'S
                ONLY FILIPINO ACTRESS". She played the important role of Luisite
                in the Metro-Goldwyn-Mayer production of "Cry Havoc". the
                all-feminine cast is headed by Margaret Sullivan, Ann Southern,
                Marsha Hunt, Joan Blondell and Fey Beinter. It was directed by
                Richard Thorpe, produced by Edwin Knopf.
              </p>
            </div>
          </section>
        </div>
      ) : (
        <div className="gallery-container2">
          <section className="biography">
            <div className="bio-image">
              {/* Add an image component here */}
              <img src={BioPic} alt="Biography" />
            </div>
            <div className="bio-content">
              <h2>BIOGRAPHY</h2>
              <p>
                {/* Your biography content goes here */}
                In the heart of Hollywood, amidst the glitz and glamour, Reena's
                formative years were shaped by her single mother's unwavering
                resilience, a testament to the immigrant spirit from the
                Philippines. From early on, she embodied the virtues of
                perseverance and forward-thinking, laying the groundwork for her
                journey ahead.
              </p>
              <p>
                Under the guidance of legendary mentors such as Lee Strasberg,
                Stella Adler, and Sanford Meisner at Playhouse West, Reena
                embarked on her artistic odyssey. Her debut alongside the iconic
                Aaliyah in "Journey To The Past" marked a profound chapter in
                her soul-stirring narrative.
              </p>
              <p>
                With a repertoire spanning blockbuster films and beloved TV
                series like "Married With Children" and "iCarly," Reena's talent
                graced screens worldwide. Not just confined to the silver
                screen, she lent her charisma to national advertising campaigns
                for renowned brands like Adidas and Coca-Cola, leaving an
                indelible imprint.
              </p>
              <p>
                The inception of her Reena Clone Blog series ignited a newfound
                passion for storytelling, propelling her into the realms of
                producing, editing, and scripting. Armed with a Communication
                Studies degree and a trail of accolades including the
                prestigious Astronaut of the Year Award, her multifaceted
                talents earned her global acclaim.
              </p>
              <p>
                Balancing between ateliers in Los Angeles and England, Reena's
                art transcends boundaries, resonating with audiences across
                diverse cultures. Her installations, from Hong Kong to Berlin,
                including poignant tributes to Kobe Bryant, adorn iconic
                streets, echoing her commitment to democratizing art.
              </p>
              <p>
                In a landmark moment, Reena made history as the first Pacific
                Islander contemporary painter to address Cambridge University's
                Conference of Human Analog Space Missions. Her impassioned
                discourse catalyzed the creation of "The Ordeal," a monumental
                painting embodying her Quantum Entanglement narrative.
              </p>
              <p>
                {" "}
                Today, as an Artist in Residency at Pas + Fig in Highland Park,
                California, Reena pioneers immersive experiences, pushing the
                boundaries of artistic expression. Inspired by her deep-seated
                commitment to social responsibility, she founded the ARTE Youth
                Program, bridging gaps in S.T.E.A.M education and spreading
                transformative experiences to impoverished communities
                worldwide.
              </p>
              <p>Ad Astra!</p>
            </div>
          </section>

          <section className="trivia">
            <div className="trivia-image">
              <img src={Fely} alt="Trivia" />
            </div>
            <div className="trivia-content">
              <h2>Reena Trivia!</h2>
              <p>
                Reena's Great Grand Aunt, Fely Franquelli, was " HOLLYWOOD'S
                ONLY FILIPINO ACTRESS". She played the important role of Luisite
                in the Metro-Goldwyn-Mayer production of "Cry Havoc". the
                all-feminine cast is headed by Margaret Sullivan, Ann Southern,
                Marsha Hunt, Joan Blondell and Fey Beinter. It was directed by
                Richard Thorpe, produced by Edwin Knopf.
              </p>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default Gallery;
