import React, { useState, useEffect } from "react";
import "../Press.css"; // Import the CSS file

import useScreenSize from "./useScreenSize";

const Collection = ({ images }) => {
  const [currentBackground, setCurrentBackground] = useState(0);
  const [nextBackground, setNextBackground] = useState(null);
  const [isFading, setIsFading] = useState(false);
  const [overlayImage, setOverlayImage] = useState(null);
  const [isVideo, setIsVideo] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const [currentImageName, setCurrentImageName] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const isMobile = useScreenSize("(max-width: 768px)");

  const isUrl = (name) => {
    return name.startsWith("http://") || name.startsWith("https://");
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setIsFading(true);

      const nextIndex = (currentBackground + 1) % images.length;
      setNextBackground(nextIndex);

      setTimeout(() => {
        setCurrentBackground(nextIndex);
        setIsFading(false);
      }, 500);
    }, 6000);

    return () => clearInterval(interval);
  }, [currentBackground, images.length]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (overlayImage) {
        if (event.key === "ArrowLeft") {
          prevImage();
        } else if (event.key === "ArrowRight") {
          nextImage();
        } else if (event.key === "Escape") {
          closeOverlay();
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [overlayImage]);

  const closeOverlay = () => {
    setOverlayImage(null);
    setIsVideo(false);
    setVideoUrl(null);
  };

  const prevImage = () => {
    const newIndex = currentIndex === 0 ? images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
    const currentImage = images[newIndex];
    setOverlayImage(currentImage.image);
    setCurrentImageName(currentImage.name);
    if (currentImage.video) {
      setVideoUrl(currentImage.video);
      setIsVideo(true);
    } else {
      setVideoUrl("");
      setIsVideo(false);
    }
  };

  const nextImage = () => {
    const newIndex = currentIndex === images.length - 1 ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
    const currentImage = images[newIndex];
    setOverlayImage(currentImage.image);
    setCurrentImageName(currentImage.name);
    if (currentImage.video) {
      setVideoUrl(currentImage.video);
      setIsVideo(true);
    } else {
      setVideoUrl("");
      setIsVideo(false);
    }
  };

  const handleClick = (image, name, video, index) => {
    setOverlayImage(image);
    setCurrentImageName(name);
    setCurrentIndex(index);
    if (video) {
      setVideoUrl(video);
      setIsVideo(true);
    } else {
      setVideoUrl("");
      setIsVideo(false);
    }
  };

  return (
    <div className="gallery-content">
      {isMobile ? (
        <div className="gallery-container-mobile">
          {images.map((item, index) => (
            <div
              className="gallery-item2"
              key={index}
              onClick={() =>
                handleClick(item.image, item.name, item.video, index)
              }
            >
              <img
                loading="lazy"
                src={item.image}
                alt={`image-${index}`}
                className="gallery-image"
              />
              {item.name ? (
                <a
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="gallery-link-mobile"
                >
                  {item.name}
                </a>
              ) : null}
            </div>
          ))}
        </div>
      ) : (
        <div className="gallery-container">
          {images.map((item, index) => (
            <div
              className="gallery-item2"
              key={index}
              onClick={() =>
                handleClick(item.image, item.name, item.video, index)
              }
            >
              <img
                loading="lazy"
                src={item.image}
                alt={`image-${index}`}
                className="gallery-image"
              />
              {item.name ? (
                <a
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="gallery-link"
                >
                  {item.name}
                </a>
              ) : null}
            </div>
          ))}
        </div>
      )}

      {overlayImage && (
        <div className="image-overlay visible" onClick={closeOverlay}>
          <div
            className={
              isMobile
                ? "image-overlay-content-mobile"
                : "image-overlay-content"
            }
          >
            {isVideo && videoUrl ? (
              <iframe
                width="100%"
                height="100%"
                src={videoUrl}
                title="Video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            ) : (
              <img loading="lazy" src={overlayImage} alt="Full view" />
            )}
            {currentImageName && currentImageName !== "" ? (
              <div className={isMobile ? "image-name-mobile" : "image-name"}>
                {isUrl(currentImageName) ? (
                  <a
                    href={currentImageName}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {currentImageName}
                  </a>
                ) : (
                  currentImageName
                )}
              </div>
            ) : null}

            <div className="navigation-arrows">
              <button
                className="prev-arrow"
                onClick={(e) => {
                  e.stopPropagation();
                  prevImage();
                }}
              >
                {/* Left arrow symbol */}
              </button>

              <button
                className="next-arrow"
                onClick={(e) => {
                  e.stopPropagation();
                  nextImage();
                }}
              >
                {/* Right arrow symbol */}
              </button>
            </div>
          </div>
          <div className="close-button" onClick={closeOverlay}>
            &times;
          </div>
        </div>
      )}
    </div>
  );
};

export default Collection;
