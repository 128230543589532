import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./components/footer/Footer";
import "./App.css";

import NaviBar from "./components/navbar/NaviBar";
import AstronautSeries from './screens/art_collections/Astronaut'
import NBA from './screens/art_collections/NBA'
import HollywoodIcons from './screens/art_collections/Icons'
import GOLD from './screens/art_collections/Picasso'
import LockdownMadeMePaintThis from './screens/art_collections/Lockdown'
import Press from './screens/Press'

import Biography from './screens/Biography'

import Contact from './screens/Contact'

import Screenshot20240907at12155AM from "./images/background/Screenshot2024-09-07at1.21.55AM.webp";
import Screenshot20240907at12222AM from "./images/background/Screenshot2024-09-07at1.22.22AM.webp";
import chandelier from "./images/background/chandelier.webp"
import Screenshot20240907at11741AM from "./images/background/Screenshot2024-09-07at1.17.41AM.webp";
import Screenshot20240907at12534AM from "./images/background/Screenshot2024-09-07at1.25.34AM.webp";


import { Analytics } from "@vercel/analytics/react";
import Home from './screens/Home2'

const backgrounds = [
  Screenshot20240907at12222AM,
  Screenshot20240907at11741AM,
  chandelier,
  Screenshot20240907at12534AM,
  Screenshot20240907at12155AM,
];


const Gallery = () => {
  const [currentBackground, setCurrentBackground] = useState(0);
  const [isFading, setIsFading] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);


  useEffect(() => {
    const interval = setInterval(() => {
      setIsFading(true); // Start fading out the current image

      setTimeout(() => {
        // Change to the next background after fading out
        setCurrentBackground(
          (prevBackground) => (prevBackground + 1) % backgrounds.length
        );
        setIsFading(false); // Fade the new image in
      }, 400); // Match the fade duration
    }, 6000); // Change every 6 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      className={`gallery-background ${isFading ? "fade-out" : "fade-in"}`}
      style={{
        backgroundImage: `url(${backgrounds[currentBackground]})`,
      }}
    ></div>
  );
};



class App extends React.Component {
  render() {
    return (
      <>
        <Router>
          <link
            href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap"
            rel="stylesheet"
          ></link>
          <navbar />
          <Gallery />
          <div className="Heading">
            <header className="App-header">
              <NaviBar />
            </header>
          </div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/AstronautSeries" element={<AstronautSeries />} />
            <Route path="/NBA" element={<NBA />} />
            <Route path="/HollywoodIcons" element={<HollywoodIcons />} />
            <Route path="/gold-inspired-by-pablo-picasso" element={<GOLD />} />
            <Route
              path="/LockdownMadeMePaintThis"
              element={<LockdownMadeMePaintThis />}
            />
            <Route path="/Press" element={<Press />} />
            <Route path="/Biography" element={<Biography />} />
            <Route path="/Contact" element={<Contact />} />
          </Routes>

          <Footer />
        </Router>
        <Analytics />
      </>
    );
  }
}

export default App;