import React, { useState, useEffect } from "react";
import "../Press.css"; // Import the CSS file


const Gallery = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  return (
    <>
      {isMobile ? (
        <div className="gallery-container-mobile">
          <section className="bio-content-mobile">
            <div className="contact-header">
              <h2>CONTACT RT</h2>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 300 15"
                preserveAspectRatio="none"
                fill="none"
                stroke="red"
                stroke-width="1"
              >
                <path d="M0 6 Q 50 0, 100 6 T 200 6 T 300 6 T 400 0" />
              </svg>
            </div>
            <div className="bio-wrapper-mobile">
              <div className="bio-content-mobile">
                <h2>
                  Art Related Commissions
                  <br />& Inquiries{" "}
                </h2>
                <p>MANAGEMENT@REENATOLENTINO.COM</p>

                <h2>
                  Print & Commercial <br />
                  Representation
                </h2>
                <p>PAT DAWSON (310) 461-3644 / PAT@PANTHEONTALENT.COM</p>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <div className="gallery-container2">
          <section className="trivia"></section>
          <section className="biography2">
            <div className="contact-header">
              <h2>CONTACT RT</h2>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 300 15"
                preserveAspectRatio="none"
                fill="none"
                stroke="red"
                stroke-width="1"
              >
                <path d="M0 6 Q 50 0, 100 6 T 200 6 T 300 6 T 400 0" />
              </svg>
            </div>

            <div className="bio-wrapper">
              <div className="bio-content2">
                <h2>
                  Art Related Commissions
                  <br />& Inquiries{" "}
                </h2>
                <p>MANAGEMENT@REENATOLENTINO.COM</p>
              </div>
              <div className="bio-content2">
                <h2>Print & Commercial Representation</h2>
                <p>PAT DAWSON (310) 461-3644 / PAT@PANTHEONTALENT.COM</p>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default Gallery;
