import React from 'react';
import SocialIcon from '../../SocialIcon'
import './Footer.css'

const Footer = () => {
    return (
      <footer className="App-footer">
        {/* <p>&copy; 2024 Julio Daniel. All rights reserved.</p> */}
        <p>
          REENATOLENTINO 2024 - Copyright{" "}
          <a
            href="https://www.instagram.com/accounts/login/?next=https%3A%2F%2Fwww.instagram.com%2Freenatolentino%2F&is_from_rle"
            target="_blank"
            rel="noreferrer"
          >
            <SocialIcon />
          </a>
          <a
            href="https://www.linkedin.com/in/reena-tolentino-0047796b?original_referer=https%3A%2F%2Fwww.google.com%2F"
            target="_blank"
            rel="noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1032 1032"
              width="30" // Set the width as needed
              height="30" // Set the height as needed
              fill="currentColor"
              className="linked-icon"
            >
              <path d="M195 349v553H11V349h184zm12-171q0 41-29 68t-75 27h-1q-46 0-74-27T0 178t29-68 75-27 74 27 29 68zm650 407v317H674V606q0-59-23-92t-71-33q-35 0-58 19t-36 48q-6 17-6 45v309H296q1-223 1-361V376l-1-27h184v80h-1q11-18 23-31t31-29 49-24 64-9q95 0 153 63t58 186z"></path>
            </svg>
          </a>
          <a
            href="https://www.youtube.com/user/reenatolentino/videos"
            target="_blank"
            rel="noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1032 1032"
              width="30" // Set the width as needed
              height="30" // Set the height as needed
              fill="currentColor"
              className="linked-icon"
            >
              <path
                fill="currentColor"
                d="M916.5 330.1s-8.3-58.6-33.8-84.4c-32.3-33.9-68.6-34-85.2-36-118.9-8.6-297.4-8.6-297.4-8.6h-.4s-178.4 0-297.4 8.6c-16.6 2-52.8 2.1-85.2 36-25.5 25.8-33.8 84.4-33.8 84.4S75 398.8 75 467.6v64.5c0 68.8 8.5 137.6 8.5 137.6s8.3 58.6 33.8 84.4c32.3 33.9 74.8 32.8 93.7 36.3 68 6.5 289 8.5 289 8.5s178.6-.3 297.6-8.9c16.6-2 52.8-2.1 85.2-36 25.5-25.8 33.8-84.4 33.8-84.4s8.5-68.8 8.5-137.6v-64.5c-.1-68.7-8.6-137.4-8.6-137.4zM412.2 610.3V371.5l229.7 119.8-229.7 119z"
              ></path>
            </svg>
          </a>
        </p>
      </footer>
    );
}

export default Footer;