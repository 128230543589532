import "../../App.css";
import Collection from "../CollectionComponent";

import image001 from "../../images/icons/001_no_caption.webp";
import image002 from "../../images/icons/002_no_caption.webp";
import image003 from "../../images/icons/003_no_caption.webp";
import image004 from "../../images/icons/004_no_caption.webp";
import image005 from "../../images/icons/005_no_caption.webp";
import image006 from "../../images/icons/006_no_caption.webp";
import image007 from "../../images/icons/007_no_caption.webp";
import image008 from "../../images/icons/008_no_caption.webp";
import image009 from "../../images/icons/009_no_caption.webp";
import image010 from "../../images/icons/010_no_caption.webp";
import image011 from "../../images/icons/011_no_caption.webp";
import image012 from "../../images/icons/012_no_caption.webp";
import image013 from "../../images/icons/013_no_caption.webp";
import image014 from "../../images/icons/014_no_caption.webp";
import image015 from "../../images/icons/015_no_caption.webp";
import image016 from "../../images/icons/016_no_caption.webp";
import image017 from "../../images/icons/017_no_caption.webp";
import image018 from "../../images/icons/018_no_caption.webp";
import image019 from "../../images/icons/019_no_caption.webp";
import image020 from "../../images/icons/020_no_caption.webp";
import image021 from "../../images/icons/021_no_caption.webp";
import image022 from "../../images/icons/022_no_caption.webp";
import image023 from "../../images/icons/023_no_caption.webp";
import image024 from "../../images/icons/024_no_caption.webp";
import image025 from "../../images/icons/025_no_caption.webp";
import image026 from "../../images/icons/026_no_caption.webp";
import image027 from "../../images/icons/027_no_caption.webp";
import image028 from "../../images/icons/028_no_caption.webp";

import basq from "../../images/icons/basq.webp"
import frida from "../../images/icons/frida.webp"
import marleydreads from "../../images/icons/marleydreads.webp"
import sophiaLoren from "../../images/icons/SophiaLoren.webp"


const images = [
  { image: marleydreads, name: ""  },
  { image: frida, name: "" },
  { image: sophiaLoren, name: "" },
  { image: basq, name: "" },
  { image: image002, name: "" },
  { image: image003, name: "" },
  { image: image004, name: "" },
  { image: image005, name: "" },
  { image: image006, name: "" },
  { image: image007, name: "" },
  { image: image008, name: "" },
  { image: image009, name: "" },
  { image: image010, name: "" },
  { image: image011, name: "" },
  { image: image012, name: "" },
  { image: image013, name: "" },
  { image: image014, name: "" },
  { image: image015, name: "" },
  { image: image016, name: "" },
  { image: image017, name: "" },
  { image: image018, name: "" },
  { image: image019, name: "" },
  { image: image020, name: "" },
  { image: image021, name: "" },
  { image: image022, name: "" },
  { image: image023, name: "" },
  { image: image024, name: "" },
  { image: image001, name: "" },
  { image: image025, name: "" },
  { image: image026, name: "" },
  { image: image027, name: "" },
  { image: image028, name: "" },
];


// import Collection from "../CollectionComponent";
const Collections = () => {
  return <Collection images={images} />;
};

export default Collections;