import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav, NavbarBrand, NavDropdown } from "react-bootstrap";
import { Drawer, List, ListItem, ListItemText, Collapse, ListItemButton } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";


import "./NavBar.css";
import NavHamburger from "./NavHamburger";
import useScreenSize from './useScreenSize'
import { styled } from "@mui/system";
// import rt from "../../images/rt-brand.png";
import rt from "../../images/rt-brand2.png";

import SocialIcon from "../../SocialIcon"


const NaviBar = () => {
  const [expanded, setExpanded] = useState(false);
  const isMobile = useScreenSize("(max-width: 768px)");
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleHamburger = () => {
    setExpanded(!expanded);
  };

  const StyledListItemText = styled(ListItemText)(({ theme }) => ({
    fontSize: "30px",
    fontWeight: "bold",
    fontFamily: "Bodoni, sans-serif",
    color: "rgb(29, 29, 29)",
    textDecoration: "none",
  }));

  // useEffect to log the isMobile state
  useEffect(() => {
    console.log(
      "Website developed by Julio Daniel using React, HTML, CSS and Javascript. Visit juliodaniel.com for details."
    ); // Log the isMobile state
  }, [isMobile]);

  const NavbarLinks = () => (
    <>
      <div className={`navbar-nav ${expanded ? "open" : ""}`}>
        <div className="red-line2" />
        <nav
          className="hoverDiv"
          onMouseEnter={() => setDropdownOpen(true)}
          onMouseLeave={() => setDropdownOpen(false)}
        >
          <Nav.Link
            data-testid="nav-link1"
            className="nav-link1"
            as={Link}
            to="/"
          >
            <div className="nav-button">RT THE ARTIST</div>
          </Nav.Link>
          <div className="nav-link">
            <a className="nav-button">
              ART COLLECTIONS
              {dropdownOpen && (
                <div className="dropdown-content">
                  <Link to="/AstronautSeries" className="dropdown-item">
                    ASTRONAUT SERIES
                  </Link>
                  <Link
                    to="/gold-inspired-by-pablo-picasso"
                    className="dropdown-item"
                  >
                    GOLD: Inspired by Picasso
                  </Link>
                  <Link to="/NBA" className="dropdown-item">
                    NBA ALL STARS
                  </Link>
                  <Link to="/HollywoodIcons" className="dropdown-item">
                    HOLLYWOOD ICONS: GONE BUT NEVER FORGOTTEN
                  </Link>
                  <Link to="/LockdownMadeMePaintThis" className="dropdown-item">
                    LOCKDOWNS MADE ME PAINT THIS
                  </Link>
                </div>
              )}
            </a>
          </div>
          <Nav.Link
            data-testid="nav-link1"
            className="nav-link"
            as={Link}
            to="/Press"
          >
            <div className="nav-button">PRESS</div>
          </Nav.Link>
          <Nav.Link
            data-testid="nav-link1"
            className="nav-link"
            as={Link}
            to="https://shop.reenatolentino.com/"
            target="_blank"
          >
            <div className="nav-button">SHOP</div>
          </Nav.Link>
          <Nav.Link
            data-testid="nav-link1"
            className="nav-link"
            as={Link}
            to="https://www.arteyouthprogram.org/"
            target="_blank"
          >
            <div className="nav-button">PHILANTHROPY</div>
          </Nav.Link>
          <Nav.Link
            data-testid="nav-link1"
            className="nav-link"
            as={Link}
            to="/Biography"
          >
            <div className="nav-button">BIOGRAPHY</div>
          </Nav.Link>
          <Nav.Link
            data-testid="nav-link1"
            className="nav-link"
            as={Link}
            to="/Contact"
          >
            <div className="nav-button">CONTACT</div>
          </Nav.Link>
        </nav>
      </div>
      <div className="red-line" />
    </>
  );

    const [openDropdown, setOpenDropdown] = useState(false);
    const handleDropdownClick = (dropdownName) => {
      setOpenDropdown((prev) => ({
        ...prev,
        [dropdownName]: !prev[dropdownName],
      }));
    };

  const DrawerLinks = () => (
    <div className={`navbar-nav ${expanded ? "open" : ""}`}>
      <Drawer
        className="drawerPaper"
        anchor="right"
        data-testid="toggles"
        open={expanded}
        onClose={toggleHamburger}
        // classes={{ paper: "drawerPaper" }}
      >
        <List>
          <ListItem component={Link} to="/" onClick={toggleHamburger}>
            <StyledListItemText primary="RT THE ARTIST" />
          </ListItem>
          <ListItemButton
            onClick={() => handleDropdownClick("ART COLLECTIONS")}
          >
            <ListItemText primary="ART COLLECTIONS" />
            {openDropdown["ART COLLECTIONS"] ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse
            in={openDropdown["ART COLLECTIONS"]}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              <ListItem
                component={Link}
                to="/AstronautSeries"
                onClick={toggleHamburger}
              >
                <ListItemText primary="ASTRONAUT SERIES" />
              </ListItem>
              <ListItem
                component={Link}
                to="/gold-inspired-by-pablo-picasso"
                onClick={toggleHamburger}
              >
                <ListItemText primary="GOLD: Inspired by Picasso" />
              </ListItem>
              <ListItem
                component={Link}
                to="/HollywoodIcons"
                onClick={toggleHamburger}
              >
                <ListItemText primary="HOLLYWOOD ICONS" />
              </ListItem>
              <ListItem
                component={Link}
                to="/LockdownMadeMePaintThis"
                onClick={toggleHamburger}
              >
                <ListItemText primary="LOCKDOWNS MADE ME PAINT THIS" />
              </ListItem>
            </List>
          </Collapse>
          <ListItem component={Link} to="/Press" onClick={toggleHamburger}>
            <StyledListItemText primary="PRESS" />
          </ListItem>
          <ListItem
            component={Link}
            to="https://shop.reenatolentino.com/"
            onClick={toggleHamburger}
          >
            <StyledListItemText primary="SHOP" />
          </ListItem>
          <ListItem
            component={Link}
            to="https://www.arteyouthprogram.org/"
            onClick={toggleHamburger}
          >
            <StyledListItemText primary="PHILANTHROPY" />
          </ListItem>
          <ListItem component={Link} to="/Biography" onClick={toggleHamburger}>
            <StyledListItemText primary="BIOGRAPHY" />
          </ListItem>
          <ListItem component={Link} to="/Contact" onClick={toggleHamburger}>
            <StyledListItemText primary="CONTACT" />
          </ListItem>
        </List>
      </Drawer>
    </div>
  );

  return (
    <Navbar className="navbar" expand="lg">
      <NavbarBrand className="navbar-brand" as={Link} to="/">
        <img className="navbar-brand-img" src={rt} alt="Logo" />
      </NavbarBrand>
      {isMobile ? (
        <>
          <NavHamburger toggleHamburger={toggleHamburger} isOpen={expanded} />
          <DrawerLinks />
        </>
      ) : (
        <NavbarLinks />
      )}
    </Navbar>
  );
};

export default NaviBar;