import "../../App.css";
import Collection from "../CollectionComponent";

import Trae_Young_with_his_one_very_own_NBA_ALL_STAR_excl from "../../images/nba/001_Trae_Young_with_his_one_very_own_NBA_ALL_STAR_excl.webp";
import Kobe_Tribute_Billboard_on_Sunset_Blvd_2020 from "../../images/nba/002_Kobe_Tribute_Billboard_on_Sunset_Blvd_2020.webp";
import RISE_OF_KOBE from "../../images/nba/003_RISE_OF_KOBE.webp";
import the_studioone_week_before_Kobes_death from "../../images/nba/004_the_studioone_week_before_Kobe's_death.webp";
import AD_2020 from "../../images/nba/005_AD_2020.webp";
import Trae_Young_2020 from "../../images/nba/006_Trae_Young_2020.webp";
import no_caption_007 from "../../images/nba/007_no_caption.webp";
import Grateful_for_the_collectors_I_have from "../../images/nba/008_Grateful_for_the_collectors_I_have.webp";
import no_caption_009 from "../../images/nba/009_no_caption.webp";
import Hollywood_Hills_CA from "../../images/nba/010_Hollywood_Hills_CA.webp";
import Steph_Curry_the_Three_Point_King from "../../images/nba/011_Steph_Curry_the_Three_Point_King.webp";
import no_caption_012 from "../../images/nba/012_no_caption.webp";
import no_caption_013 from "../../images/nba/013_no_caption.webp";
import no_caption_014 from "../../images/nba/014_no_caption.webp";
import no_caption_015 from "../../images/nba/015_no_caption.webp";
import no_caption_016 from "../../images/nba/016_no_caption.webp";
import no_caption_017 from "../../images/nba/017_no_caption.webp";
import no_caption_018 from "../../images/nba/018_no_caption.webp";
import Kawhi_Leonard_2020 from "../../images/nba/019_Kawhi_Leonard_2020.webp";
import no_caption_022 from "../../images/nba/022_no_caption.webp";
import jordan23 from "../../images/nba/MichaelJordan23.webp"

const images = [
  { image: AD_2020, name: "AD 2020" },
  { image: jordan23, name: "Michael Jordan 23" },
  { image: no_caption_007, name: "" },
  { image: Trae_Young_2020, name: "Trae Young 2020" },
  {
    image: Trae_Young_with_his_one_very_own_NBA_ALL_STAR_excl,
    name: "Trae Young with his one very own NBA ALL STAR exclusive portrait",
  },
  {
    image: Grateful_for_the_collectors_I_have,
    name: "Grateful for the collectors I have.",
  },
  { image: no_caption_009, name: "" },
  { image: Hollywood_Hills_CA, name: "Hollywood Hills, CA" },
  {
    image: Steph_Curry_the_Three_Point_King,
    name: "Steph Curry the Three Point King.",
  },
  {
    image: Kobe_Tribute_Billboard_on_Sunset_Blvd_2020,
    name: "Kobe Tribute Billboard on Sunset Blvd 2020",
  },
  { image: RISE_OF_KOBE, name: "RISE OF KOBE" },
  {
    image: the_studioone_week_before_Kobes_death,
    name: "The studio one week before Kobe's death.",
  },
  { image: no_caption_012, name: "" },
  { image: no_caption_013, name: "" },
  { image: no_caption_014, name: "" },
  { image: no_caption_015, name: "" },
  { image: no_caption_016, name: "" },
  { image: no_caption_017, name: "" },
  { image: no_caption_018, name: "" },
  { image: Kawhi_Leonard_2020, name: "Kawhi Leonard 2020" },
  { image: no_caption_022, name: "" },
];



const Collections = () => {
  return <Collection images={images} />;
};

export default Collections;