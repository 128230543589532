import "../../App.css";
import Collection from "../CollectionComponent";

import view from "../../images/lockdown/01viewla.webp";
import google from "../../images/lockdown/02googlethis.webp";
import rj from "../../images/lockdown/03romeojuliet.webp";
import hands from "../../images/lockdown/04washinghands.webp";


const images = [
  {
    image: view,
    name: "VIEW OF LA (SELF-PORTRAIT), 2020  ACRYLIC, OIL PASTEL ON CANVAS 36 IN X 47 IN",
  },
  {
    image: google,
    name: "GOOGLE THIS, 2020  ACRYLIC, OIL PASTEL ON CANVAS 36 IN X 36 IN",
  },
  {
    image: hands,
    name: "WASHING HANDS (SELF- PORTRAIT), 2020  ACRYLIC, OIL PASTEL, ON CANVAS 43 IN X 109 IN",
  },
  {
    image: rj,
    name: "ROMEO + JULIET, 2020  ACRYLIC, OIL PASTEL ON CANVAS 27 IN X 36 IN",
  },
];



const Collections = () => {
  return <Collection images={images} />;
};

export default Collections;