import React, { useState, useEffect} from "react";
import "../Press.css"; // Import the CSS file

// Import renamed images
import american from "../images/press/american.png";
import blockster from "../images/press/blockster.com.png";
import collections from "../images/press/collectio.png";
import hbx from "../images/press/hbx.com.png";
import issue from "../images/press/issue.png";
import limitedKindaGuy from "../images/press/limited-kinda-guy.png";
import meetReenaTolentino2 from "../images/press/meet-reena-tolentino-2.png";
import meetReenaTolentinoRt from "../images/press/meet-reena-tolentino-rt.png";
import meetReenaTolentino from "../images/press/meet-reena-tolentino.png";
import myBusinessWire from "../images/press/my-business-wire.png";
import nm3632657 from "../images/press/nm3632657.png";
import post from "../images/press/post.png";
import rawartists from "../images/press/rawartists.com.png";
import reenaTolentino from "../images/press/reena-tolentino.png";
import risingStarsMeetReenaTolentino from "../images/press/rising-stars-meet-reena-tolentino-of-west-hollywood.png";
import runDmcReenaTolentino from "../images/press/run-dmc-xreena-tolentino.png";
import shopOkayplayer from "../images/press/shop.okayplayer.com.png";
import styleGuydeWordpress from "../images/press/thestyleguyde.wordpress.com.png";
import isaacAlvarez from "../images/press/www.isaacalvarez.com.png";
import canvasRebel from "../images/press/canvasrebel.webp"

import Screenshot20240907at12143AM from "../images/background/Screenshot2024-09-07at1.21.43AM.webp";
import Screenshot20240907at12155AM from "../images/background/Screenshot2024-09-07at1.21.55AM.webp";
import Screenshot20240907at12222AM from "../images/background/Screenshot2024-09-07at1.22.22AM.webp";
import Screenshot20240907at12240AM from "../images/background/Screenshot2024-09-07at1.22.40AM.webp";
import Screenshot20240907at12452AM from "../images/background/Screenshot2024-09-07at1.24.52AM.webp";
import Screenshot20240907at11644AM from "../images/background/Screenshot2024-09-07at1.16.44AM.webp";
import chandelier from "../images/background/chandelier.webp";

// List of images and URLs
const imageList = [
  { image: canvasRebel, url: "https://canvasrebel.com/meet-r-t/" },
  { image: hbx, url: "https://hbx.com/?no-direct=1" },
  { image: rawartists, url: "https://rawartists.com/reenatolentino" },
  {
    image: limitedKindaGuy,
    url: "https://centmagazine.co.uk/limited-kinda-guy/",
  },
  { image: reenaTolentino, url: "https://upmag.com/tag/reena-tolentino/" },
  { image: collections, url: "https://12on12.com/collections/all-products" },
  { image: shopOkayplayer, url: "https://shop.okayplayer.com/" },
  { image: isaacAlvarez, url: "https://www.isaacalvarez.com/" },
  {
    image: risingStarsMeetReenaTolentino,
    url: "http://voyagela.com/interview/rising-stars-meet-reena-tolentino-of-west-hollywood/",
  },
  { image: styleGuydeWordpress, url: "https://thestyleguyde.wordpress.com/" },
  {
    image: blockster,
    url: "https://blockster.com/world-of-water-art-fair-ape-water-fawc-host-spectacular-event-at-outer-edge-la-2023",
  },
  {
    image: runDmcReenaTolentino,
    url: "https://www.touchofmodern.com/sales/run-dmc-xreena-tolentino/1 20n1 2-run-dmc-record-limited-edition? wall-I",
  },
  {
    image: issue,
    url: "https://www.magcloud.com/browse/issue/2584205?__r=6284445",
  },
  {
    image: myBusinessWire,
    url: "https://www.businesswire.com/portal/site/home/my-business-wire/",
  },
  { image: nm3632657, url: "https://m.imdb.com/name/nm3632657/" },
  {
    image: post,
    url: "https://www.mrla-media.com/post/reena-tolentino-a-tapestry-of-art-and-inspiration",
  },
  {
    image: meetReenaTolentinoRt,
    url: "https://boldjourney.com/news/meet-reena-tolentino-rt/",
  },
  {
    image: meetReenaTolentino2,
    url: "https://canvasrebel.com/meet-reena-tolentino-2/",
  },
  {
    image: meetReenaTolentino,
    url: "https://boldjourney.com/news/meet-reena-tolentino/",
  },
  { image: american, url: "https://www.americanmodelingacademy.com" },
];

const backgrounds = [
      // Screenshot20240907at12311AM,
      // Screenshot20240907at11553AM,
    // Screenshot20240907at12352AM,
    Screenshot20240907at12222AM,
    Screenshot20240907at12452AM,
    chandelier,
    Screenshot20240907at11644AM,
    // Screenshot20240907at12000AM,
    //   Screenshot20240907at12416AM,
    //   Screenshot20240907at11741AM,
    // Screenshot20240907at11529AM,
//   Screenshot20240907at11752AM,
//   Screenshot20240907at12506AM,
//   Screenshot20240907at11944AM,
//   Screenshot20240907at12534AM,
//   Screenshot20240907at12547AM,
//   Screenshot20240907at12029AM,
//   Screenshot20240907at124703PM,
//   Screenshot20240907at12126AM,
  Screenshot20240907at12143AM,
  Screenshot20240907at12155AM,
// //   Screenshot20240907at81601PM,
  Screenshot20240907at12240AM,
];



const Gallery = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  return (
    <div className="gallery-wrapper">
      {isMobile ? (
        <div className="gallery-content">
          <div className="gallery-container-mobile">
            {imageList.map((item, index) => (
              <div className="gallery-item" key={index}>
                <a
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="gallery-link2"
                >
                  <img
                    src={item.image}
                    alt={`image-${index}`}
                    className="gallery-image"
                  />
                </a>
                <a
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="gallery-link"
                >
                  {item.url}
                </a>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="gallery-content">
          <div className="gallery-container">
            {imageList.map((item, index) => (
              <div className="gallery-item" key={index}>
                <a
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="gallery-link2"
                >
                  <img
                    src={item.image}
                    alt={`image-${index}`}
                    className="gallery-image"
                  />
                </a>
                <a
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="gallery-link"
                >
                  {item.url}
                </a>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Gallery;
