import "../../App.css";
import Collection from "../CollectionComponent"

import box from "../../images/astronaut/boxpainting.webp";
import thinking from "../../images/astronaut/spacethinking.webp";
import universe from "../../images/astronaut/spaceuniverse.webp";

import follow_your_gut_oil_pastel_acrylic_on_canvas from "../../images/astronaut/002_Follow_your_gut_Oil_pastel_acrylic_on_canvas.webp";
import selene_princess from "../../images/astronaut/003_Selene_Princess.webp";
import in_the_studio_creating_with_astronauts_2019 from "../../images/astronaut/004_In_the_studio_creating_with_Astronauts_2019.webp";
import reena_at_cambridge_university_showing_her_trilogy_series from "../../images/astronaut/005_Reena_at_Cambridge_University_showing_her_trilogy_series.webp";
import reena_conducting_her_dark_painting_project_at_cambridge_university from "../../images/astronaut/006_Reena_conducting_her_Dark_Painting_project_at_Cambridge_University.webp";
import oddict_pods_campaign_2021 from "../../images/astronaut/007_Oddict_pods_Campaign_2021.webp";
import oddict_pods_campaign_2021_alternate from "../../images/astronaut/008_Oddict_pods_Campaign_2021.webp";
import risks from "../../images/astronaut/011_RISKS.webp";
import tethered_hearts from "../../images/astronaut/012_Tethered_Hearts.webp";
import space_dreams_oil_pastel_acrylic_on_canvas from "../../images/astronaut/014_Space_Dreams_Oil_pastel_acrylic_on_canvas.webp";
import spooky_action_at_a_distance_based_on_einsteins_epr_theory_of_quantum_entanglement_4ft_by_4ft_acrylic_on_canvas_and_oil_pastels from "../../images/astronaut/015_SPOOKY_ACTION_AT_A_DISTANCE.webp";
import twenty_twenty_one from "../../images/astronaut/016_2021.webp";
import twin_flame_4ft_by_4f_acrylic_on_canvas_and_oil_pastel from "../../images/astronaut/017_TWIN_FLAME.webp";
import Astronaut_John_Glen from "../../images/023_Astronaut_John_GlenThe_mediums_are_Oil,_Watercolor,_and_Acrylic.18_by_20_inches.Glows_In_The_Dark.webp";
import Astronaut_Bruce_McCandles from "../../images/024_Astronaut_Bruce_McCandles_.The_mediums_are_Oil,_Watercolor,_and_Acrylic.18_by_20_inches.Glows_In_The_Dark.webp";
import Astronaut_Neil_Armstrong from "../../images/025_Astronaut_Neil_ArmstrongThe_mediums_are_Oil,_Watercolor,_and_Acrylic.18_by_20_inches.Glows_In_The_Dark.webp";


const images = [
  { image: universe, name: "The Universe" },
  {
    image: follow_your_gut_oil_pastel_acrylic_on_canvas,
    name: "Follow your gut. Oil pastel, acrylic on canvas",
  },
  { image: selene_princess, name: "Selene Princess." },
  {
    image: in_the_studio_creating_with_astronauts_2019,
    name: "In the studio creating with Astronauts 2019",
  },
  { image: oddict_pods_campaign_2021, name: "Oddict pods Campaign 2021" },
  {
    image: oddict_pods_campaign_2021_alternate,
    name: "Oddict pods Campaign 2021",
  },
  { image: risks, name: "RISKS." },
  { image: tethered_hearts, name: "Tethered Hearts." },
  { image: thinking, name: "" },
  { image: box, name: "" },
  {
    image: reena_at_cambridge_university_showing_her_trilogy_series,
    name: "Reena at Cambridge University showing her trilogy series.",
  },
  {
    image: reena_conducting_her_dark_painting_project_at_cambridge_university,
    name: 'Reena conducting her "Dark Painting" project at Cambridge University',
  },
  {
    image: Astronaut_John_Glen,
    name: "Astronaut John Glen, Glows in the Dark",
  },
  {
    image: Astronaut_Neil_Armstrong,
    name: "Astronaut Neil Armstrong   The mediums are Oil, Watercolor, and Acrylic.  18 by 20 inches.  Glows In The Dark",
  },
  {
    image: Astronaut_Bruce_McCandles,
    name: "Astronaut Bruce McCandles .  The mediums are Oil, Watercolor, and Acrylic.  18 by 20 inches.  Glows In The Dark",
  },
  {
    image: space_dreams_oil_pastel_acrylic_on_canvas,
    name: "Space Dreams. Oil pastel, acrylic on canvas.",
  },
  {
    image:
      spooky_action_at_a_distance_based_on_einsteins_epr_theory_of_quantum_entanglement_4ft_by_4ft_acrylic_on_canvas_and_oil_pastels,
    name: '"SPOOKY ACTION AT A DISTANCE" based on Einstein\'s "EPR" theory of Quantum Entanglement4FT by 4FT.Acrylic on canvas and oil pastels',
  },
  { image: twenty_twenty_one, name: "2021" },
  {
    image: twin_flame_4ft_by_4f_acrylic_on_canvas_and_oil_pastel,
    name: '"TWIN FLAME"4FT by 4FTAcrylic on canvas and oil pastel.',
  },
];


const Collections = () => {

  return (
    <Collection images={images} />
  );
};

export default Collections;
